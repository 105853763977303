import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import { io } from 'socket.io-client';
import QRCode from "react-qr-code";
import axios from 'axios';

function PairingPage(props) {
  const { param } = useParams();
  const clientId = param;
  
  const [pairingEvents, setPairingEvents] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!clientId) return
    const socket = io(`${process.env.REACT_APP_WA_CONNECT_SOCKET_URL}`,{
    query: {
      clientId: clientId,
    },
    forceNew: true,
    
    });
   
    setLoading(true);
    axios.get(`${process.env.REACT_APP_WA_CONNECT_BASE_URL}/onConnectStatus/${clientId}`, {headers: {
      'apikey': `${process.env.REACT_APP_WA_CONNECT_APIKEY}`
    }})
    .then(function (response) {
      if (response.status === 200) {
        console.log('response ?', response.data)
        setPairingEvents(response.data)
        socket.connect();
   
        socket.on(`${clientId}`, onPairingEvent);
            
        function onPairingEvent(value) {
          console.log('event value', value)
          setPairingEvents(value);
        }
         
        return () => { ;
          socket.off(`${clientId}`, onPairingEvent); 
          socket.disconnect();
        }; 
  
      } else {
        setPairingEvents({evt:"error",value: "gagal mendapatkan status device"});
      }
    }).catch(e => { 
      let msg = e.message;
      if (e.response && e.response.data) {
        msg = e.response.data;
      }
      setPairingEvents({evt:"error",value: msg});
    }).finally(() => {
      setLoading(false);
    }); 
  }, [clientId]);

  if (!clientId) {    
    return (
      <div className="App">
        <header className="App-header">  
          <p>Include kan client id pada url untuk mengecek status pairing device dan wa service</p>
        </header>
      </div>
    )
  }

  return (
    <div className="App">
    <header className="App-header">  
    <p>Status Device</p>
    {
      (loading) ? 
        <ClipLoader
          color={"white"}
          loading={loading}
          //cssOverride={override}
          size={150}
          aria-label="Loading ..."
          data-testid="loader"
        />
      :
      (pairingEvents) ? (pairingEvents.evt === 'qr') ? 
      <div style={{ height: "auto", padding: 25, maxWidth: 750, width: "100%", background: 'white' }}>
      <p style={{color:'black', fontWeight: 'bold'}}>Silahkan Scan dari WA untuk menghubungkan Device </p>
          <QRCode 
          size={500}
          //style={{ height: "auto", maxWidth: "100%", width: "100%"   }}
          value={pairingEvents.value}
          viewBox={`0 0 500 500`}
          />
      </div>
      : (pairingEvents.evt === 'ready') ?
      <div>
        <p>{pairingEvents.value}</p> 
      </div>
      : <div>{pairingEvents.value}</div> : ""
    }
    
    
    </header>
    </div>
    );
  }

export default PairingPage;
