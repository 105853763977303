import { useEffect } from "react";
// import Experience from "../Experience";
// import Header from "../Header";
// import Price from "../Price";
// import Maps from "../Receipt/Maps";
// import Receipt from "../Receipt/Receipt";
// import ThisIsACopy from "../ThisIsACopy";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// import { pdfjs, Document, Page } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();


function MainPage(props) { 
  // const navigate = useNavigate(); 

  useEffect(() => { 
  }, []);

  //console.log(data);

  return (
    <div className="body-container">
      WA Connect by loginusa <br />
      go to pairing page <a href="/pairing">Click Here</a>
    </div>
  );
}

export default MainPage;
